<template>
    <footer>
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-12 col-lg-10">
                    <logo-component class="logo" :light="true"/>
                    <p v-if="showText1" class="f12 white mt-4 mb-0">PayTel&nbsp;SA jest Krajową Instytucją Płatniczą (KIP), w&nbsp;rozumieniu Ustawy o&nbsp;usługach płatniczych z&nbsp;dnia
                        19 sierpnia 2011&nbsp;r. (Dz.U. 2011 nr&nbsp;199 poz. 1175 ze&nbsp;zm.). Nadzór nad spółką sprawuje Komisja Nadzoru
                        Finansowego, która zarejestrowała PayTel&nbsp;S.A. jako KIP pod numerem IP32/2015.</p>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
import LogoComponent from "@/components/LogoComponent";

export default {
    name: 'FooterComponent',
    components: {LogoComponent},
    props:{
        showText1:{
            type: Boolean,
            default: true
        }
    }
}
</script>

<style scoped lang="scss">
footer {
    padding: 48px 0;
    text-align: center;
    background: #0D1B33;
    position: relative;

    .logo {
        width: 100%;
        max-width: 189px;
        position: relative;
        z-index: 12;
    }
    .white{
        color: #ababab;
    }
}
</style>
