<template>
  <div :class="['header', {'active': open}, {'scroll': scroll}, {'dark': dark}]">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="d-flex flex-row justify-content-between justify-content-xl-start align-items-center">
            <div class="logo mr-5">
              <a href="/">
                <logo-component class="w-100" :light="dark"/>
              </a>
            </div>
            <div>
              <nav>
                <ul class="list-unstyled">
                  <li><router-link to="/">O LightPOS</router-link></li>
<!--                  <li><router-link to="/konkurs">Konkurs</router-link></li>-->
                  <li><router-link to="/qa">Q&A</router-link></li>
                  <li><router-link to="/cennik">Cennik</router-link></li>
                  <li><router-link to="/kontakt">Kontakt</router-link></li>
                </ul>
              </nav>
            </div>
            <div class="d-block d-xl-none">
              <div class="hamburger" @click="open = !open"><span/><span/><span/></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import LogoComponent from "@/components/LogoComponent";
export default {
  name: "NavigationComponent",
  components: {LogoComponent},
  data() {
    return {
      open: false,
      scroll: false,
    }
  },
  computed: {
    dark(){
      return this.$route.name === 'competition';
    }
  },
  watch: {
    '$route': function () {
      this.open = false;
    }
  },
  mounted() {
    this.scrollEvent();
  },
  methods: {
    scrollEvent() {
      document.addEventListener('scroll', () => {
        this.scroll = window.scrollY > 100;
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import "@/assets/sass/variables";
.header {
  width: 100%;
  padding: 30px 0;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 6;
  background: rgba(255,255,255,.5);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  transition: background-color .4s ease-out;
  .logo {
    width: 100%;
    max-width: 189px;
    position: relative;
    z-index: 12;
    margin-right: 100px;
  }

  nav {
    transition: background-color .4s ease-out;
    ul {
      display: block;
      padding: 0;
      margin: 0;

      li {
        display: inline-block;
        margin: 0;

        a {
          @extend .f14;
          display: block;
          padding: 7px 0;
          font-weight: 700;
          margin: 0 15px;
          color: #1F66B0;
          transition: .4s ease-out;
          position: relative;
          &::before{
            content: '';
            width: 0;
            height: 2px;
            position: absolute;
            top: 50%;
            left: 0;
            transform: translate(0, 10px);
            background: #1F66B0;
            transition: .4s ease-out;
          }
          &:hover {
            &::before {
              width: 100%;
            }
          }
          &.router-link-exact-active {
            color: $cMain;
            text-shadow: 0 0 0 $cMain;
            text-decoration: none;
            &::before {
              width: 100%;
            }
          }
        }
      }
    }
  }
  .hamburger {
    width: 40px;
    height: 19px;
    z-index: 99;
    cursor: pointer;
    display: none;
    position: relative;
    transition: .4s ease-out;

    span {
      width: 100%;
      height: 2px;
      background: $cSec;
      display: block;
      position: absolute;
      left: 0;
      transition: .4s ease-out;

      &:nth-child(1) {
        top: 0;
      }

      &:nth-child(2) {
        top: 8px;
      }

      &:nth-child(3) {
        top: 16px;
      }
    }
  }

  &.active {
    .hamburger span {
      &:nth-child(1) {
        transform: rotate(45deg) translate(8px, 4px);
      }

      &:nth-child(2) {
        width: 0;
        transform: translate(-18px, 0);
        opacity: 0;
      }

      &:nth-child(3) {
        transform: rotate(-45deg) translate(8px, -3px);
      }
    }
  }

  @media (max-width: 1199px) {
    padding: 20px 0;
    .products-navigation {
      display: none;
    }
    nav {
      width: 100vw;
      height: 100vh;
      padding-top: 20px;
      background: #fff;
      position: fixed;
      top: 0;
      left: 0;
      opacity: 0;
      transform: scale(3);
      transform-origin: 50% 50%;
      visibility: hidden;
      transition: .4s ease-out;

      ul {
        width: 100%;
        position: absolute;
        top: 50%;
        left: 0;
        transform: translate(0, -50%);

        li {
          display: block;
          text-align: center;

          a {
            background: transparent;
            padding: 5px 20px;
            margin: 5px 0;
            color: $cSec;
            display: inline-block;
            text-align: center;
            &::before{
              display: none;
            }
          }
        }
      }
    }
    .hamburger {
      display: block;
    }
    &.active {
      nav {
        opacity: 1;
        visibility: visible;
        transform: scale(1);
      }
    }
  }
  &.dark{
    background: rgba(0,0,0,.5);
    @media (max-width: 1199px) {
      nav {
        background: rgba(0,0,0);
      }
    }
    nav ul li a {
      color: $cSec;
      &::before{
        background: $cSec;
      }
      &.router-link-exact-active {
        color: #fff;
        text-shadow: 0 0 0 $cSec;
      }
    }
  }
}

@supports (-moz-appearance:none) {
  .header{
    background: rgba(255,255,255,.9);
    &.dark {
      background: rgba(0, 0, 0, .9);
    }
  }
}
</style>
