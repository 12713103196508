<template>
    <div class="main-container main" id="app">
        <navigation-form-component />
        <main :class="{'dark': dark}">
            <transition name="scale-fade" mode="out-in">
                <router-view/>
            </transition>
        </main>
        <footer-component :show-text1="false"/>
        <cookie-component/>
    </div>
</template>

<style lang="scss">

</style>
<script>
import FooterComponent from "@/components/FooterComponent";
import CookieComponent from "@/components/CookieComponent";
import NavigationFormComponent from "@/components/NavigationFormComponent.vue";

export default {
    components: {
        NavigationFormComponent,
        CookieComponent,
        FooterComponent,
    },
    computed: {
        dark() {
            return this.$route.name === 'competition';
        }
    },
}
</script>

<style lang="scss">
@import "src/assets/sass/app";
.header {
   width: 100%;
   padding: 30px 0;
   position: absolute;
   top: 0;
   left: 0;
   z-index: 90;
   .logo {
       width: 100%;
       max-width: 189px;
       position: relative;
       z-index: 12;
       margin-right: 100px;
   }
}
main {
    background: #fff;
    transition: background .4s ease-out;
    position: relative;
    overflow: hidden;

    &.dark {
        background: $cMain;
    }
}
</style>
