<template>
    <div class="main-container main" id="app">
        <main :class="{'dark': dark}">
            <bg-component/>
            <navigation-component/>
            <transition name="scale-fade" mode="out-in">
                <router-view/>
            </transition>
        </main>
        <footer-component/>
        <cookie-component/>
    </div>
</template>

<script>
import NavigationComponent from "@/components/NavigationComponent";
import FooterComponent from "@/components/FooterComponent";
import BgComponent from "@/components/BgComponent";
import CookieComponent from "@/components/CookieComponent";

export default {
    name: 'MainLayout',
    components: {
        CookieComponent,
        BgComponent,
        FooterComponent,
        NavigationComponent
    },
    computed: {
        dark(){
            return this.$route.name === 'competition';
        }
    },
}
</script>

<style lang="scss">
@import "src/assets/sass/app";
main{
    background: #fff;
    transition: background .4s ease-out;
    position: relative;
    overflow: hidden;
    &.dark{
        background: $cMain;
    }
}
</style>
<style lang="scss" scoped>

</style>
