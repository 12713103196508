import { render, staticRenderFns } from "./NavigationFormComponent.vue?vue&type=template&id=70a9f124&scoped=true&"
import script from "./NavigationFormComponent.vue?vue&type=script&lang=js&"
export * from "./NavigationFormComponent.vue?vue&type=script&lang=js&"
import style0 from "./NavigationFormComponent.vue?vue&type=style&index=0&id=70a9f124&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "70a9f124",
  null
  
)

export default component.exports