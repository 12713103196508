<template>
  <div class="bg-elements">
    <div class="gradient-box gradient-box--1" :class="pageName"></div>
    <div class="gradient-box gradient-box--2" :class="pageName"></div>
    <div class="gradient-box gradient-box--3" :class="pageName"></div>
    <div class="gradient-box gradient-box--4" :class="pageName"></div>
    <div class="square square--1" :class="pageName">
      <div class="gradient" :style="parallax"/>
    </div>
    <div class="square square--2" :class="pageName">
      <div class="gradient" :style="parallax"/>
    </div>
    <div class="square square--3" :class="pageName">
      <div class="gradient" :style="parallax"/>
    </div>
    <div class="square square--4" :class="pageName">
      <div class="gradient" :style="parallax"/>
    </div>
    <div class="square square--5" :class="pageName">
      <div class="gradient" :style="parallax"/>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BgComponent',
  data(){
    return {
      parallax: null,
    }
  },
  computed: {
    pageName(){
      return this.$route.name;
    }
  },
  mounted() {
    this.parallaxInit();
  },
  methods:{
    parallaxInit () {
      document.addEventListener('scroll', () => {
        let windowScroll = window.scrollY;
        if(window.innerWidth > 1200) {
          this.parallax = {
            "transform": `rotate(${windowScroll * 0.02}deg)`
          };
        }
      })
    }
  }
}
</script>
