import Vue from 'vue'
import VueRouter from 'vue-router'
import MainLayout from '../layouts/MainLayout'
import FormLayout from '../layouts/FormLayout'

Vue.use(VueRouter)

const routes = [
    // {
    //     path: '/',
    //     component: MainLayout,
    //     children: [{
    //         path: '',
    //         name: 'home',
    //         component: () => import('../pages/HomePage')
    //     }]
    // },
    {
        path: '/konkurs',
        component: MainLayout,
        children: [{
            path: '',
            name: 'competition',
            component: () => import('../pages/CompetitionPage')
        }]
    },
    {
        path: '/qa',
        component: MainLayout,
        children: [{
            path: '',
            name: 'qa',
            component: () => import('../pages/QAPage')
        }]
    },
    {
        path: '/cennik',
        component: MainLayout,
        children: [{
            path: '',
            name: 'price-list',
            component: () => import('../pages/PriceListPage')
        }]
    },
    {
        path: '/kontakt',
        component: MainLayout,
        children: [{
            path: '',
            name: 'contact',
            component: () => import('../pages/ContactPage')
        }]
    },
    {
        path: '/podziekowanie',
        component: MainLayout,
        children: [{
            path: '',
            name: 'thank-you',
            component: () => import('../pages/MainThankYouPage')
        }]
    },
    {
        path: '/',
        component: FormLayout,
        children: [{
            path: '',
            name: 'form',
            component: () => import('../pages/FormPage')
        }]
    },
    {
        path: '/404',
        component: MainLayout,
        children: [{
            path: '',
            component: () => import('../pages/NotFound')
        }]
    },
    {
        path: '*',
        redirect: '/404'
    },
]

const router = new VueRouter({
    mode: 'history',
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        }
        if (to.hash) {
            return window.scrollTo({
                top: document.querySelector(to.hash).offsetTop - 20,
                behavior: 'smooth'
            })
        }
        return {x: 0, y: 0}
    },
    routes
})

export default router
